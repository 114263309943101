// Generated by Framer (772c36b)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import CompareImage from "https://framerusercontent.com/modules/FqNSbrFNRKIgDjGtvZ6b/csqUnaqBqhzsiR94BUA2/CompareImage.js";
const CompareImageFonts = getFonts(CompareImage);

const cycleOrder = ["THph289LZ"];

const serializationHash = "framer-HQcz0"

const variantClassNames = {THph289LZ: "framer-v-c2cdeh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "THph289LZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-c2cdeh", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"THph289LZ"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><ComponentViewportProvider ><motion.div className={"framer-11ti1sy-container"} layoutDependency={layoutDependency} layoutId={"gBRu0rk_j-container"}><CompareImage background={"rgb(51, 51, 51)"} borderRadius={50} height={"100%"} id={"gBRu0rk_j"} imageRadius={20} images={[]} labelStyles={{afterBackgroundColor: "rgba(255, 255, 255, 0.2)", afterBorderRadius: 4, afterFont: 10, afterTextColor: "rgb(255, 255, 255)", beforeBackgroundColor: "rgba(255, 255, 255, 0.2)", beforeBorderRadius: 4, beforeFont: 10, beforeTextColor: "rgb(255, 255, 255)"}} layoutId={"gBRu0rk_j"} padding={10} style={{height: "100%", width: "100%"}} svgCode={""} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-HQcz0.framer-1bm31s7, .framer-HQcz0 .framer-1bm31s7 { display: block; }", ".framer-HQcz0.framer-c2cdeh { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 200px; justify-content: center; padding: 0px; position: relative; width: 200px; }", ".framer-HQcz0 .framer-11ti1sy-container { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-HQcz0.framer-c2cdeh { gap: 0px; } .framer-HQcz0.framer-c2cdeh > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-HQcz0.framer-c2cdeh > :first-child { margin-top: 0px; } .framer-HQcz0.framer-c2cdeh > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerz5wr7G5jP: React.ComponentType<Props> = withCSS(Component, css, "framer-HQcz0") as typeof Component;
export default Framerz5wr7G5jP;

Framerz5wr7G5jP.displayName = "CompareImage";

Framerz5wr7G5jP.defaultProps = {height: 200, width: 200};

addFonts(Framerz5wr7G5jP, [{explicitInter: true, fonts: []}, ...CompareImageFonts], {supportsExplicitInterCodegen: true})